import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "sm:col-12 md:col-12 lg:col-12 p-0 border-noround border-none mt-3 ml-6" }
const _hoisted_2 = {
  key: 0,
  class: "menutab-item"
}
const _hoisted_3 = { class: "sm:col-12 md:col-12 lg:col-12 ml-0 p-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_TabView = _resolveComponent("TabView")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_TabView, {
        scrollable: true,
        class: "customers-menu-tabview sales-menu-tabview tabview-panels-hidden",
        onTabChange: _ctx.handleTabChange,
        activeIndex: this.activeTab,
        "onUpdate:activeIndex": _cache[0] || (_cache[0] = ($event: any) => ((this.activeTab) = $event))
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (tab) => {
            return (_openBlock(), _createBlock(_component_TabPanel, {
              key: tab.label
            }, {
              header: _withCtx(() => [
                (_ctx.CheckTabRights(tab.command))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _createVNode(_component_router_link, {
                        to: tab.to,
                        class: "menutab-item-link"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Button, {
                            label: tab.label,
                            class: "menutab-button"
                          }, null, 8, ["label"])
                        ]),
                        _: 2
                      }, 1032, ["to"])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }, 8, ["onTabChange", "activeIndex"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_router_view)
    ])
  ]))
}