
import { defineComponent } from "vue";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Button from "primevue/button";
import { mapActions, mapGetters, mapState } from "vuex";
export default defineComponent({
  components: {
    TabView,
    TabPanel,
    Button,
  },

  data() {
    return {
      activeTab: 0 as number,
      items: [
        {
          label: "Original Orders",
          to: "/manufacturing/original-orders",
          command: "CW.MAN.ORIG",
          index: 0,
        },
        {
          label: "Open Order Status",
          to: "/manufacturing/orders",
          command: "CW.MAN.SALES",
          index: 1,
        },
        {
          label: "Work In Progress",
          to: "/manufacturing/WIP",
          command: "CW.MAN.WIP",
          index: 2,
        },
        {
          label: "Not Released",
          to: "/manufacturing/not-released",
          command: "CW.MAN.NR",
          index: 3,
        },
        {
          label: "Released",
          to: "/manufacturing/released",
          command: "CW.MAN.REL",
          index: 4,
        },
        {
          label: "Bulk Left Over",
          to: "/manufacturing/bulk",
          command: "CW.MAN.BULK",
          index: 5,
        },
        {
          label: "Archive",
          to: "/manufacturing/archive",
          command: "CW.MAN.ARCHIVE",
          index: 6,
        },
        {
          label: "Deleted",
          to: "/manufacturing/deleted",
          command: "CW.MAN.DELETED",
          index: 7,
        },
        {
          label: "Search",
          to: "/manufacturing/search",
          command: "CW.MAN.SEARCH",
          index: 8,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getUser: "session/getUser",
    }),
  },
  watch: {
    $route(to, from) {
      this.findTabIndexByPath(to.path);
    },
  },
  methods: {
    ...mapActions({
      changeTitle: "title/changeTitle",
    }),
    changeToolBarTitle(sideMenuLabel: any) {
      this.changeTitle(sideMenuLabel.label);
    },
    handleTabChange(e: any) {
      this.$emit("index-changed", e);
    },
    findTabIndexByPath(path: any) {
      const tab = this.items.find((item) => path.includes(item.to));
      this.activeTab = tab?.index || 0;
      return tab ? tab.index : null;
    },
    CheckTabRights(command: string) {
      let currentUserDetails = this.getUser;

      let commandName = command as string;
      if (
        Object.prototype.hasOwnProperty.call(
          currentUserDetails.commands_rights,
          commandName,
        )
      ) {
        let commandView = currentUserDetails.commands_rights[commandName].view;
        return commandView;
      } else {
        return false;
      }
    },
  },
});
